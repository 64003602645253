<template>
  <div class="wraper pagePDlist">
    <el-drawer
        size="335px"
        :modal="false"
        :append-to-body="false"
        :modal-append-to-body="false"
        :visible.sync="drawer"
        :with-header="false">
      <div class="drawer_box">
        <el-row style="margin-bottom: 10px;" class="flexEnd flex_1 ">
          <el-button size="small" type="primary" plain icon="el-icon-search"
                     @click="onSearch">查询
          </el-button>
          <el-button size="small" type="primary" plain
                     @click="onReset">重置
          </el-button>
        </el-row>
        <el-divider style="width: 100%"></el-divider>
        <el-form :model="queryForm" ref="queryForm" label-width="10px">
          <el-row>筛选</el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="">
                <el-input v-model="queryForm.title" size="mini" placeholder="盘点单名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="">
                <el-select v-model="queryForm.status" size="mini" placeholder="盘点状态">
                  <el-option label="盘点中" :value="0"></el-option>
                  <el-option label="已完成" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="">
                <el-date-picker
                    v-model="queryForm.start_date"
                    type="date" size="mini"
                    value-format="yyyy-MM-dd"
                    placeholder="创建起始时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="">
                <el-date-picker
                    v-model="queryForm.end_date"
                    type="date" size="mini"
                    value-format="yyyy-MM-dd"
                    placeholder="创建结束时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-drawer>

    <el-row style="margin-bottom: 10px;" class="flexBetween flex_1 ">
      <div class="flex_1">
        <el-button size="small" type="primary" plain icon="el-icon-plus"
                   @click="add">新增
        </el-button>
      </div>
      <div>
        <el-input v-model="queryForm.title" size="small" clearable style="width: 200px!important;" placeholder="盘点单名称">
          <el-button slot="append" size="small" icon="el-icon-search" @click="getList"></el-button>
        </el-input>
        <el-button type="text" @click="drawer=true"
                   style="color: #999999;margin-left: 10px;">
          <i class="el-icon-s-operation"></i>&nbsp;高级搜索</el-button>
      </div>
    </el-row>
    <el-table
        :data="DataList" height="calc( 100% - 90px)"
        @selection-change="handleSelectionChange"
        style="width: 100%;" stripe border size="small">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="操作" min-width="80">
        <template slot-scope="scope">
          <el-tag size="mini" @click="editItem(scope.row)"><i class="el-icon-edit"></i></el-tag>
          <el-tag type="danger" size="mini" style="margin-left: 10px;"
                  @click="removeItem(scope.row)"><i class="el-icon-delete"></i></el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="盘点单状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">盘点中</span>
          <span v-if="scope.row.status==1">已完成</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="盘点单名称" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="name" label="盘点人员"></el-table-column>
      <el-table-column prop="name" label="审核人员"></el-table-column>
      <el-table-column prop="name" label="计划盘点时间" min-width="90px" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="name" label="创建人"></el-table-column>
      <el-table-column prop="name" label="创建时间" :show-overflow-tooltip="true"></el-table-column>
    </el-table>
    <Pagination :pagination="page" @paginate="paginate"></Pagination>
    <!--盘点单-->
    <el-dialog title="盘点单" :visible.sync="dialogVisible" width="720px" custom-class="width_800 cus_dialog">
      <el-form :model="EditItem" ref="EditItem" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="盘点单名称:">
              <el-input v-model="EditItem.title" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="盘点人:">
              <el-button size="mini" type="primary" plain
                         @click="showUser(1)">设置</el-button>
              <el-tag v-if="EditItem.user_name" style="margin-left: 10px;"
                      type="success" closable @close="delTag(1)">{{EditItem.user_name}}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="审核人:">

              <el-button size="mini" type="primary" plain
                         @click="showUser(2)">设置</el-button>
              <el-tag v-if="EditItem.user2_name" style="margin-left: 10px;"
                      type="success" closable @close="delTag(2)">{{EditItem.user2_name}}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="计划盘点时间:">
              <div class="flexAround">
                <el-date-picker
                    v-model="EditItem.start_date"
                    type="date" size="mini" :disabled="disabled"
                    value-format="yyyy-MM-dd"
                    placeholder="选择开始日期">
                </el-date-picker>
                <div style="margin: 0 10px;">-</div>
                <el-date-picker
                    v-model="EditItem.end_date"
                    type="date" size="mini" :disabled="disabled"
                    value-format="yyyy-MM-dd"
                    placeholder="选择结束日期">
                </el-date-picker>
              </div>

            </el-form-item>
          </el-col>
        </el-row>
        <el-divider style="width: 100%"></el-divider>
        <el-row>
          <div style="color: #409EFF;margin: 10px 0 10px 0;">盘点范围</div>
          <el-col :span="24">
            <el-form-item label="资产分类:">
              <el-cascader
                  ref="myCascader1"
                  v-model="EditItem.zc_typeid"
                  :options="typeList" size="mini" :disabled="disabled"
                  :props="optionProps1" :show-all-levels="true" filterable
                  placeholder="请选择"
                  @change="handleCascaderChange1"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所在位置:">
              <el-cascader
                  ref="myCascader"
                  v-model="EditItem.zc_storeid"
                  :options="areaList" size="mini" :disabled="disabled"
                  :props="optionProps" :show-all-levels="true" filterable
                  placeholder="请选择"
                  @change="handleCascaderChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注:">
              <el-input type="textarea" :autosize="{ minRows: 3}"
                        v-model="EditItem.remark" size="mini" :disabled="disabled"
                        placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="disabled" style="margin-top: 20px;">
          <el-table
              :data="logList"
              border
              style="width: 100%">
            <el-table-column
                prop="处理时间"
                label="日期"
                width="180">
            </el-table-column>
            <el-table-column
                prop="name"
                label="处理人"
                width="180">
            </el-table-column>
            <el-table-column
                prop="address"
                label="处理方式">
            </el-table-column>
            <el-table-column
                prop="address"
                label="处理内容">
            </el-table-column>
            <el-table-column
                prop="address"
                label="签字图片">
            </el-table-column>
            <el-table-column
                prop="address"
                label="备注">
            </el-table-column>
          </el-table>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="saveForm">保存</el-button>
      </div>
    </el-dialog>
    <Deptuser v-if="showUserDialog" @getUser="getUser" @closeUser="closeUser"></Deptuser>
  </div>
</template>

<script>
import Pagination from '../../com/pagination.vue'
import Deptuser from '../../com/deptuser.vue'
export default {
  components: {
    Pagination,
    Deptuser,
  },
  data() {
    return {
      DataList:[],
      multipleSelection:[],
      queryForm:{},
      page: {
        current_page: 1,
        pre_page:10,
        count: 0
      },
      dialogVisible: false,
      EditItem:{},
      disabled:false,
      typeList:[],
      areaList:[],
      optionProps:{value: 'id', label: 'storename',emitPath: false, checkStrictly: true},
      optionProps1:{value: 'id', label: 'cname',emitPath: false},
      files:[],
      logList:[],
      dialogVisible_borrow:false,
      userinfo:{},
      dialogVisible_return:false,
      dialogVisible_distribute:false,
      dialogVisible_withdraw:false,
      drawer: false,//高级搜索面板
      showUserDialog:false,
      setUsetType:'',
      teacIdx: 0,
    }
  },
  mounted() {
    this.getList();
    this.getType();
    this.getArea();
    let _this = this
    if (localStorage.getItem("user")) {
      _this.userinfo = JSON.parse(localStorage.getItem("user"))
      // console.log(_this.userinfo)
    }
  },
  methods: {
    getType () {
      let _this = this
      this.$http.post("/api/zc_type",{type:'zc'}).then(res => {
        _this.deleteEmptyGroup(res.data).then(result=>{
          _this.typeList = result
        })
      })
    },
    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result=>{
          _this.areaList = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item=>{
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }
        traversal(treeData)
        resolve(treeData)
      })
    },
    getList() {
      return
      let _this = this
      let params = {}
      params.page = this.page.current_page
      params.pagesize = this.page.pre_page
      if(_this.queryForm!={}){
        for(let key in _this.queryForm){
          params.ktype = key
          params.keyword = _this.queryForm[key]
        }
      }
      this.$http.post("/api/zc_list",params).then(res => {
        this.DataList = res.data.data;
        this.page = res.data.page
      })
    },

    uploadCover(e){
      console.log(e)
      this.files = [{filename:e.fileName,src:e.src,name:e.fileName,url:e.src}]
    },
    uploadPreview(file){
      // console.log(879,file)
      window.open(file.src||file.url)
    },

    onSearch(){
      this.page.current_page=1
      this.getList()
    },
    onReset(){
      this.queryForm = {}
      this.page.current_page=1
      this.getList()
    },
    handleSelectionChange(val){
      this.multipleSelection = val;
      console.log(val)
    },
    showDetails(row){
      let _this = this
      this.editItem(row)
      this.getLogs(row.id)
      this.dialogVisible = true
      this.disabled = true
    },

    showUser (type, idx) {
      let _this = this
      this.showUserDialog = true
      this.setUsetType = type
      if (type == 6) {
        _this.teacIdx = idx
      }
    },
    getUser (e) {
      this.setLinkInfo = e[0].name
      let _this = this
      switch (_this.setUsetType) {
        case 1:
          _this.EditItem.user = e[0].union_id
          _this.EditItem.user_name = e[0].name
          break;
        case 2:
          _this.EditItem.user2 = e[0].union_id
          _this.EditItem.user2_name = e[0].name
          break;
      }
      _this.showUserDialog = false
    },
    closeUser () {
      this.showUserDialog = false
    },
    delTag(a,idx,item){
      let _this = this
      switch (a) {
        case 1:
          delete _this.EditItem.user
          delete _this.EditItem.user_name
          break;
        case 2:
          delete _this.EditItem.user2
          delete _this.EditItem.user2_name
          break;
      }
      _this.EditItem = {..._this.EditItem}
    },

    getLogs(id){
      this.$http.post("/api/zc_logs",{zc_id:id}).then(res => {
        console.log(res)
        this.logList = res.data;
      })
    },
    add(){
      this.dialogVisible = true
      this.disabled = false
      this.EditItem = {}
      this.files = []
    },
    editItem(row){
      let _this = this
      this.dialogVisible = true
      this.disabled = false
      this.EditItem = row
      let arr = []
      if(row.files!=''&&row.files!=null&&row.files!=undefined){
        arr = JSON.parse(row.files)
        _this.files =  [{filename:arr[0].filename,src:arr[0].src,name: arr[0].filename, url:arr[0].src}]
      }

    },
    saveForm() {
      let _this = this
      if(!this.EditItem.id){
        this.EditItem.autocode = 1
      }
      if(this.files.length>0){
        this.EditItem.files = JSON.stringify(_this.files)
      }
      this.$http.post('/api/zc_save', _this.EditItem).then(res => {
        _this.dialogVisible = false;
        this.$message({
          type: 'success',
          message: '保存成功'
        });
        _this.getList()
      })
    },
    removeItem(item){
      let _this = this
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/zc_delete", {
          id: item.id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          _this.getList()
        })

      }).catch(() => {

      });

    },
    paginate(val){
      this.page.current_page = val
      this.getList();
    },
    handleCascaderChange(e){
      console.log(e)
    },
    handleCascaderChange1(e){
      this.EditItem.zc_typeid = e
      let a = this.$refs['myCascader1'].getCheckedNodes()[0].data
      this.EditItem.ename = a.ename
    },

  }
}
</script>

<style lang="less" type="text/scss">
.pagePDlist{
  .el-input,.el-select,.el-cascader{
    width: 100% !important;
  }
  .el-dropdown {
    vertical-align: top;
  }
  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF!important;
      background-color: #Ecf5ff!important;
      border-color: #b3d8ff!important;
    }
  }
  .el-divider{
    margin-top: 0;
    margin-bottom: 0px;
  }
  .el-drawer__wrapper{
    top: 70px;
  }
  .drawer_box{
    padding: 10px;
    .el-form-item {
      margin-bottom: 0px;
    }
  }
}
</style>
